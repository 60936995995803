import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";

export default function Filling({
  curFilling,
  Error,
  curFillingFlavor,
  disableSubmit,
}) {
  const [val, setVal] = useState();
  useEffect(() => {
    const fillings = () => {
      return fetch(
        "https://7z692oqj5k.execute-api.us-east-1.amazonaws.com/Prod",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => JSON.parse(data[0][2]).fillings)
        .then((fillings) => {
          let cakeSizes = fillings.map((fillings, index) => (
            <option
              key={index}
              value={fillings.type}
              data-price={fillings.price || 0}
            >
              {fillings.type}
            </option>
          ));
          setVal(cakeSizes);
        });
    };
    fillings();
  }, []);
  return (
    <>
      <label>Filling</label>
      <Field
        name="Filling"
        component="select"
        ref={curFilling}
        onClick={(e) => {
          disableSubmit();
          e.preventDefault();
          try {
            curFillingFlavor.current.options[
              curFillingFlavor.current.selectedIndex
            ].setAttribute("value", "");
            curFillingFlavor.current.dispatchEvent(
              new Event("change", { bubbles: true })
            );
          } catch (err) {
            console.log(err);
          }
        }}
      >
        <option value="">Select One</option>
        {val}
        <option value="">Skip</option>
      </Field>
      <Error name="Filling" />
    </>
  );
}
