import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";

export default function Frosting({ Error, curFrosting, curFrostingFlavor }) {
  const [val, setVal] = useState();
  useEffect(() => {
    const frostings = () => {
      return fetch(
        "https://7z692oqj5k.execute-api.us-east-1.amazonaws.com/Prod",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => JSON.parse(data[0][3]).frostings)
        .then((frostings) => {
          let cakeSizes = frostings.map((frostings, index) => (
            <option key={index} value={frostings.type}>
              {frostings.type}
            </option>
          ));
          setVal(cakeSizes);
        });
    };
    frostings();
  }, []);
  return (
    <>
      <label>Frosting</label>
      <Field
        name="Frosting"
        component="select"
        ref={curFrosting}
        onClick={() => {
          try {
            curFrostingFlavor.current.options[
              curFrostingFlavor.current.selectedIndex
            ].setAttribute("value", "");
            curFrostingFlavor.current.dispatchEvent(
              new Event("change", { bubbles: true })
            );
          } catch (err) {
            console.log(err);
          }
        }}
      >
        <option value="">Select One</option>
        {val}
        <option value="none">Skip</option>
      </Field>
      <Error name="Frosting" />
    </>
  );
}
