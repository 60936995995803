import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";

export default function FillingFlavors({
  Error,
  curFillingFlavor,
  idx,
  required,
}) {
  try {
    const [val, setVal] = useState();
    useEffect(() => {
      const fillings = () => {
        return fetch(
          "https://7z692oqj5k.execute-api.us-east-1.amazonaws.com/Prod",
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => JSON.parse(data[0][2]).fillings)
          .then((fillings) => {
            let cakeSizes = fillings[idx - 1].flavors.map((fillings, index) => (
              <option key={index} value={fillings}>
                {fillings}
              </option>
            ));
            setVal(cakeSizes);
          });
      };
      fillings();
    }, [idx]);
    return (
      <>
        <Field
          name="Filling_Flavors"
          component="select"
          ref={curFillingFlavor}
          validate={required}
        >
          <option value="">Select One</option>
          {val}
          <option value="">Skip</option>
        </Field>
        <Error name="Filling_Flavors" />
      </>
    );
  } catch (err) {
    console.log(err);
  }
}
