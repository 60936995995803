import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";

export default function Flavors({ required, Error }) {
  const [val, setVal] = useState();
  useEffect(() => {
    const flavors = () => {
      return fetch(
        "https://7z692oqj5k.execute-api.us-east-1.amazonaws.com/Prod",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => JSON.parse(data[0][1]).flavors)
        .then((flavors) => {
          let cakeSizes = flavors.map((flavors, index) => (
            <option key={index} value={flavors}>
              {flavors}
            </option>
          ));
          setVal(cakeSizes);
        })
        .catch((err) => console.log(err))
        .finally();
    };
    flavors();
  }, []);
  return (
    <div>
      <label>Flavor</label>
      <Field name="Flavor" component="select" validate={required}>
        <option value="">Select One</option>
        {val}
      </Field>
      <Error name="Flavor" />
    </div>
  );
}
