import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";

export default function Size({ curSize, Error, required, disableSubmit }) {
  const [val, setVal] = useState();

  useEffect(() => {
    const sizes = () => {
      return fetch(
        "https://7z692oqj5k.execute-api.us-east-1.amazonaws.com/Prod",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => JSON.parse(data[0][5]).sizes)
        .then((sizes) => {
          let cakeSizes = sizes.map((size, index) => (
            <option
              key={index}
              value={size.amount}
              data-price={size.price || 0}
            >
              {size.amount}
            </option>
          ));
          setVal(cakeSizes);
        })
        .catch((err) => console.log(err));
    };
    sizes();
  }, []);

  return (
    <div>
      <label>Number of Cupcakes</label>
      <Field
        name="Size"
        component="select"
        ref={curSize}
        validate={required}
        onClick={() => disableSubmit()}
      >
        <option value="">Select One</option>
        {val}
      </Field>
      <Error name="Size" />
    </div>
  );
}
