import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";

export default function FrostingFlavors({
  Error,
  curFrostingFlavor,
  idx,
  required,
}) {
  try {
    const [val, setVal] = useState();
    useEffect(() => {
      const frostings = () => {
        return fetch(
          "https://7z692oqj5k.execute-api.us-east-1.amazonaws.com/Prod",
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => JSON.parse(data[0][3]).frostings)
          .then((frostings) => {
            let cakeSizes = frostings[idx - 1].flavors.map(
              (frostings, index) => (
                <option key={index} value={frostings}>
                  {frostings}
                </option>
              )
            );
            setVal(cakeSizes);
          });
      };
      frostings();
    }, [idx]);
    return (
      <>
        <Field
          name="Frosting_Flavors"
          component="select"
          validate={required}
          ref={curFrostingFlavor}
        >
          <option value="">Select One</option>
          {val}
          <option value="">Skip</option>
        </Field>
        <Error name="Frosting_Flavors" />
      </>
    );
  } catch (err) {}
}
