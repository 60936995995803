import React, { useRef, useState } from "react";
import "../style.css";
import Styles from "../Styles";
import { Form, Field } from "react-final-form";
import Size from "../components/Size";
import Flavors from "../components/Flavors";
import Frosting from "../components/Frosting";
import Filling from "../components/Filling";
import FrostingFlavors from "../components/FrostingFlavors";
import FillingFlavors from "../components/FillingFlavors";
import Topping from "../components/Topping";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
);

const Fields = ({
  names,
  subscription,
  fieldsState = {},
  children,
  originalRender,
}) => {
  if (!names.length) {
    return (originalRender || children)(fieldsState);
  }
  const [name, ...rest] = names;
  return (
    <Field name={name} subscription={subscription}>
      {(fieldState) => (
        <Fields
          names={rest}
          subscription={subscription}
          originalRender={originalRender || children}
          fieldsState={{ ...fieldsState, [name]: fieldState }}
        />
      )}
    </Field>
  );
};
export default function Calculator() {
  const curQuantity = useRef();
  const curSize = useRef();
  const curTopping = useRef();
  const curFilling = useRef();
  const curFrosting = useRef();
  const curFillingFlavor = useRef();
  const curFrostingFlavor = useRef();
  const curTotal = useRef();
  const submitButton = useRef();
  const calcButton = useRef();
  const [cartTotal, setCartTotal] = useState([]);
  const [cart, setCart] = useState([]);
  let curCart = [];
  const doRemove = (event) => {
    let confirmRemove = confirm("Are you certain?");
    if (confirmRemove === true) {
      const newTotal = cartTotal - cart[event.index].total;
      setCartTotal(newTotal);
      cart.splice(event.index, 1);
      setCart([...cart]);
    } else {
      return;
    }
  };
  const enableSubmit = () => {
    submitButton.current.style.display = "block";
    calcButton.current.style.display = "none";
    curQuantity.current.setAttribute("disabled", "true");
  };
  const disableSubmit = () => {
    submitButton.current.style.display = "none";
    calcButton.current.style.display = "block";
    curQuantity.current.removeAttribute("disabled");
  };
  const Cart = () => {
    try {
      const curCartVals = cart.map((curCartVals, index) => (
        <div
          key={index}
          style={{ flexDirection: "column", alignItems: "flex-end" }}
        >
          {curCartVals.Size && (
            <div>
              <strong>Number of Cup Cakes: </strong>
              {curCartVals.Size}
            </div>
          )}
          {curCartVals.Flavor && (
            <div>
              <strong>Flavor: </strong>
              {curCartVals.Flavor}
            </div>
          )}
          {curCartVals.Filling && (
            <div>
              <strong>Filling: </strong>
              {curCartVals.Filling}
              {curCartVals.Filling_Flavors ? (
                <strong> ({curCartVals.Filling_Flavors})</strong>
              ) : null}
            </div>
          )}
          {curCartVals.Frosting && (
            <div>
              <strong>Frosting: </strong>
              {curCartVals.Frosting}
              {curCartVals.Frosting_Flavors ? (
                <strong> ({curCartVals.Frosting_Flavors})</strong>
              ) : null}
            </div>
          )}
          {curCartVals.Topping && (
            <div>
              <strong>Topping: </strong>
              {curCartVals.Topping}
            </div>
          )}
          {curCartVals.notes && (
            <div>
              <strong>Notes: </strong>
              {curCartVals.notes}
            </div>
          )}
          {curCartVals.Quantity_In_Order >= 1 && (
            <div>
              <strong>Quantity in order: </strong>
              {curCartVals.Quantity_In_Order}
            </div>
          )}
          {curCartVals.Quantity_In_Order > 0 && (
            <div>
              <hr />
              <strong>Total: </strong>$
              {Math.round(curCartVals.total).toFixed(2)}
            </div>
          )}
          <remove
            value={index}
            type="button"
            onClick={(e) => doRemove({ index })}
            style={{
              backgroundColor: "red",
              padding: "10",
              borderRadius: 5,
              color: "white",
            }}
          >
            Remove
          </remove>
        </div>
      ));

      return <>{curCartVals}</>;
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (values) => {
    await sleep(300);
    cart.push(values);
    let sum = cart.reduce(function (prev, current) {
      return prev + +current.total;
    }, 0);
    setCartTotal(sum);
  };

  const totalCalc = () => {
    enableSubmit();
    let fillingVal = curFilling.current.options[
      curFilling.current.selectedIndex
    ].dataset.price
      ? parseInt(
          curFilling.current.options[curFilling.current.selectedIndex].dataset
            .price
        )
      : 0;
    let sizeVal = curSize.current.options[curSize.current.selectedIndex].dataset
      .price
      ? parseInt(
          curSize.current.options[curSize.current.selectedIndex].dataset.price
        )
      : 0;
    let toppingVal = curTopping.current.options[
      curTopping.current.selectedIndex
    ].dataset.price
      ? parseInt(
          curTopping.current.options[curTopping.current.selectedIndex].dataset
            .price
        )
      : 0;
    let quantity = curQuantity.current ? curQuantity.current.value : 1;
    let newVal = (toppingVal + sizeVal + fillingVal) * quantity;
    curTotal.current.setAttribute("value", newVal);
    curTotal.current.dispatchEvent(new Event("change", { bubbles: true }));
  };

  const required = (value) => (value ? undefined : "Required");
  const mustBeNumber = (value) =>
    isNaN(value) ? "Must be a number" : undefined;
  const minValue = (min) => (value) =>
    isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  return (
    <Styles>
      <h1>Delicious N’ Sweet!!!</h1>
      <h2>Cupcake Order Form</h2>
      <p>Please follow the steps below to build your perfect cupcake! Hahaha</p>
      <Form
        initialValues={{}}
        validate={(foo) => console.log("validating", foo)}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form,
          reset,
          submitting,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Size
              curSize={curSize}
              Error={Error}
              required={required}
              totalCalc={totalCalc}
              disableSubmit={disableSubmit}
            />
            <Flavors
              Error={Error}
              required={required}
              curFrosting={curFrosting}
            />
            <div>
              <Filling
                curFilling={curFilling}
                Error={Error}
                curFillingFlavor={curFillingFlavor}
                render={curFillingFlavor}
                disableSubmit={disableSubmit}
              />
              {values.Filling ? (
                <FillingFlavors
                  Error={Error}
                  idx={curFilling.current.selectedIndex}
                  curFilling={curFilling}
                  curFillingFlavor={curFillingFlavor}
                  required={required}
                />
              ) : null}
            </div>
            <div>
              <Frosting
                Error={Error}
                curFrosting={curFrosting}
                curFrostingFlavor={curFrostingFlavor}
              />
              {values.Frosting ? (
                <FrostingFlavors
                  Error={Error}
                  idx={curFrosting.current.selectedIndex}
                  curFrosting={curFrosting}
                  curFrostingFlavor={curFrostingFlavor}
                  required={required}
                />
              ) : null}
            </div>
            <Topping
              curTopping={curTopping}
              Error={Error}
              disableSubmit={disableSubmit}
            />
            <div>
              <label>Notes</label>
              <Field name="notes" component="textarea" placeholder="Notes" />
              <Error name="notes" />
            </div>
            <div>
              <label style={{ width: "80%" }}>Total</label>
              <Field
                name="total"
                component="input"
                validate={required}
                ref={curTotal}
                disabled
                style={{ width: "20%" }}
              />
              <Error name="notes" />
            </div>
            <hr />
            {values.Size && (
              <div>
                <label style={{ width: "80%" }}>
                  How many <strong>{values.Size}</strong> would you like?
                </label>
                <Field
                  style={{ width: "20%" }}
                  name="Quantity_In_Order"
                  component="input"
                  type="number"
                  ref={curQuantity}
                  defaultValue={1}
                  validate={composeValidators(
                    required,
                    mustBeNumber,
                    minValue(1)
                  )}
                />
              </div>
            )}
            <div className="buttons">
              <calculate
                type="button"
                onClick={totalCalc}
                disabled={submitting || pristine}
                ref={calcButton}
                style={{
                  backgroundColor: "green",
                  padding: "10",
                  borderRadius: 5,
                  color: "white",
                }}
              >
                Calculate Total
              </calculate>
              <button
                type="submit"
                disabled={submitting}
                ref={submitButton}
                onClick={() => {
                  disableSubmit();
                }}
                style={{ display: "none" }}
              >
                Submit
              </button>
              {/*<button
                type="button"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                Reset
              </button>*/}
            </div>
            {cart.length ? <Cart cartItems={cart} /> : null}
            <hr />
            <div style={{ flexDirection: "column", alignItems: "flex-end" }}>
              <strong>Cart Total: </strong>$
              {cartTotal > 0 && Math.round(cartTotal).toFixed(2)}
            </div>
          </form>
        )}
      />
    </Styles>
  );
}
