import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";

export default function ({ curTopping, Error, disableSubmit }) {
  const [val, setVal] = useState();
  useEffect(() => {
    const toppings = () => {
      return fetch(
        "https://7z692oqj5k.execute-api.us-east-1.amazonaws.com/Prod",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => JSON.parse(data[0][4]).toppings)
        .then((toppings) => {
          let cakeSizes = toppings.map((toppings, index) => (
            <option
              key={index}
              value={toppings.topping}
              data-price={toppings.price || 0}
            >
              {toppings.topping}
            </option>
          ));
          setVal(cakeSizes);
        });
    };
    toppings();
  }, []);
  return (
    <div>
      <label>Topping</label>
      <Field
        name="Topping"
        component="select"
        ref={curTopping}
        onClick={() => disableSubmit()}
      >
        <option value="">Select One</option>
        {val}
        <option value="none">Skip</option>
      </Field>
      <Error name="Topping" />
    </div>
  );
}
